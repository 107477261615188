import React from 'react';
import { motion } from 'framer-motion';
import { 
  FaRobot, FaDiscord, FaStream, FaShoppingCart, 
  FaCar, FaHotel, FaBriefcase, FaUserClock, 
  FaDatabase, FaBitcoin, FaNetworkWired 
} from 'react-icons/fa';

const Projects = () => {
  const projects = [
    // Personal Projects
    {
      title: "Jarvis Application",
      description: "AI-driven personal assistant with 50+ voice commands, streamlining user access to web browsers and media platforms. Achieved 40% reduction in task completion time.",
      icon: <FaRobot />,
      tech: ["Python", "Speech Recognition", "AI/ML"],
      gradient: 'bg-gradient-to-br from-blue-600 to-cyan-500',
      category: "Personal"
    },
    {
      title: "Covid19 Stats Discord Bot",
      description: "Real-time COVID-19 statistics bot for Discord, providing global and Indian state-wise data updates.",
      icon: <FaDiscord />,
      tech: ["Python", "discord.py", "REST APIs"],
      gradient: 'bg-gradient-to-br from-indigo-600 to-purple-500',
      category: "Personal"
    },
    // Voizzit Projects
    {
      title: "Streaming Platform",
      description: "Global-scale streaming platform with unique features impacting millions of users worldwide.",
      icon: <FaStream />,
      tech: ["Next.js", "NestJS", "Redis", "PostgreSQL", "AWS"],
      gradient: 'bg-gradient-to-br from-red-600 to-pink-500',
      category: "Professional"
    },
    // Innobayt Projects
    {
      title: "Crypto E-commerce Platform",
      description: "Advanced e-commerce marketplace with integrated cryptocurrency wallets, Stripe, and Google Pay.",
      icon: <FaShoppingCart />,
      tech: ["React", "Node.js", "Crypto Wallets", "Stripe", "Google Pay"],
      gradient: 'bg-gradient-to-br from-green-600 to-teal-500',
      category: "Professional"
    },
    // Intuio Projects
    {
      title: "Automotive Quote System",
      description: "Custom quoting system providing instant automotive price estimations with advanced calculation logic.",
      icon: <FaCar />,
      tech: ["React", "Node.js", "MongoDB", "REST APIs"],
      gradient: 'bg-gradient-to-br from-yellow-600 to-red-500',
      category: "Professional"
    },
    {
      title: "Vacation Rental Platform",
      description: "Comprehensive vacation rental platform with smart B2C API integrations and booking management.",
      icon: <FaHotel />,
      tech: ["React", "Node.js", "MongoDB", "Third-party APIs"],
      gradient: 'bg-gradient-to-br from-purple-600 to-pink-500',
      category: "Professional"
    },
    {
      title: "Smart Job Portal",
      description: "Intelligent job portal with profile matching and streamlined recruitment workflows.",
      icon: <FaBriefcase />,
      tech: ["React", "Node.js", "MongoDB", "Machine Learning"],
      gradient: 'bg-gradient-to-br from-blue-600 to-purple-500',
      category: "Professional"
    },
    // Internship Projects
    {
      title: "Visitor Management System",
      description: "Digital visitor check-in and appointment tracking system for enterprise use.",
      icon: <FaUserClock />,
      tech: ["C#", ".NET", "SQL Server"],
      gradient: 'bg-gradient-to-br from-cyan-600 to-blue-500',
      category: "Internship"
    },
    {
      title: "Database Digitization System",
      description: "System for digitizing and organizing paper records into a centralized database.",
      icon: <FaDatabase />,
      tech: ["Database Design", "Data Entry Systems"],
      gradient: 'bg-gradient-to-br from-teal-600 to-green-500',
      category: "Internship"
    },
    {
      title: "Industrial IoT Platform",
      description: "Developed a comprehensive IoT platform for industrial automation, integrating sensors, real-time monitoring, and automated control systems.",
      icon: <FaRobot />,
      tech: ["IoT", "MQTT", "Node.js", "Real-time Processing", "Sensor Integration"],
      gradient: 'bg-gradient-to-br from-purple-600 to-blue-500',
      category: "Professional"
    },
    {
      title: "Crypto Payment Gateway",
      description: "Implemented a secure cryptocurrency payment system with multiple wallet integrations and automated transaction processing.",
      icon: <FaBitcoin />,
      tech: ["Web3.js", "Blockchain", "Smart Contracts", "Payment Processing"],
      gradient: 'bg-gradient-to-br from-yellow-600 to-orange-500',
      category: "Professional"
    },
    {
      title: "Automation Control System",
      description: "Built an industrial automation control system with real-time monitoring, predictive maintenance, and process optimization.",
      icon: <FaNetworkWired />,
      tech: ["PLC Integration", "Industrial IoT", "Real-time Monitoring", "Process Control"],
      gradient: 'bg-gradient-to-br from-green-600 to-blue-500',
      category: "Professional"
    }
  ];

  return (
    <section className="py-20 bg-dark-200" id="projects">
      <div className="container mx-auto px-4">
        <motion.h1 
          className="text-4xl font-bold text-center mb-16 bg-gradient-primary bg-clip-text text-transparent"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
        >
          Featured Projects
        </motion.h1>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-6xl mx-auto">
          {projects.map((project, index) => (
            <motion.div
              key={index}
              className="bg-dark-100 rounded-xl overflow-hidden shadow-lg hover:shadow-xl transition-all border border-accent-primary/10"
              whileHover={{ y: -10 }}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <div className={`h-2 ${project.gradient}`} />
              <div className="p-8">
                <div className="flex items-center mb-4">
                  <div className="w-10 h-10 flex items-center justify-center text-accent-primary">
                    {React.cloneElement(project.icon, { className: 'w-6 h-6' })}
                  </div>
                  <h3 className="text-xl font-bold text-white ml-4">{project.title}</h3>
                </div>
                <p className="text-gray-300 mb-6">{project.description}</p>
                <div className="flex flex-wrap gap-2">
                  {project.tech.map((tech, i) => (
                    <span
                      key={i}
                      className="px-3 py-1 bg-dark-200 text-accent-primary rounded-full text-sm border border-accent-primary/20"
                    >
                      {tech}
                    </span>
                  ))}
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Projects; 