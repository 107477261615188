import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FaEnvelope, FaGithub, FaLinkedin, FaMapMarkerAlt, FaPhone, FaPaperPlane } from 'react-icons/fa';
import SEO from './SEO';
import { config } from '../config';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [status, setStatus] = useState({ type: '', message: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setStatus({ type: '', message: '' });

    try {
      const response = await fetch(`${config.apiEndpoint}/contact`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (response.ok) {
        setStatus({ type: 'success', message: 'Message sent successfully!' });
        setFormData({ name: '', email: '', subject: '', message: '' });
      } else {
        console.error('Server error:', data);
        setStatus({ 
          type: 'error', 
          message: data.message || 'Failed to send message. Please try again.' 
        });
      }
    } catch (error) {
      console.error('Submission error:', error);
      setStatus({ 
        type: 'error', 
        message: 'Network error. Please check your connection and try again.' 
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <SEO 
        title="Contact"
        description="Get in touch with Addjoy Barreto. Senior Software Engineer based in Dubai, UAE."
        path="/contact"
      />
      <section className="py-20 bg-dark-200 min-h-screen relative overflow-hidden">
        {/* Animated background */}
        <div className="absolute inset-0 overflow-hidden pointer-events-none">
          <div className="absolute w-[800px] h-[800px] bg-accent-primary/5 rounded-full blur-3xl -top-96 -left-96 animate-float"></div>
          <div className="absolute w-[600px] h-[600px] bg-neon-purple/5 rounded-full blur-3xl top-1/2 -right-96 animate-float" style={{ animationDelay: '2s' }}></div>
        </div>

        <div className="container mx-auto px-4 relative z-10">
          <div className="max-w-6xl mx-auto">
            {/* Header Section */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="text-center mb-16"
            >
              <h1 className="text-5xl font-bold mb-6 bg-gradient-primary bg-clip-text text-transparent">
                Get In Touch
              </h1>
              <p className="text-gray-400 text-lg max-w-2xl mx-auto">
                Have a question or want to work together? I'd love to hear from you!
              </p>
            </motion.div>

            {/* Main Content */}
            <div className="grid grid-cols-1 lg:grid-cols-5 gap-8">
              {/* Contact Info */}
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                className="lg:col-span-2 space-y-6"
              >
                <div className="bg-dark-100 rounded-2xl p-8 border border-accent-primary/10">
                  <h2 className="text-2xl font-bold text-white mb-6">Contact Information</h2>
                  
                  <div className="space-y-6">
                    <div className="flex items-center group">
                      <div className="w-12 h-12 rounded-xl bg-accent-primary/10 flex items-center justify-center group-hover:bg-accent-primary/20 transition-colors">
                        <FaEnvelope className="w-5 h-5 text-accent-primary" />
                      </div>
                      <div className="ml-4">
                        <p className="text-gray-400 text-sm">Email</p>
                        <a 
                          href={`mailto:${config.contactEmail}`} 
                          className="text-white hover:text-accent-primary transition-colors"
                        >
                          {config.contactEmail}
                        </a>
                      </div>
                    </div>

                    <div className="flex items-center group">
                      <div className="w-12 h-12 rounded-xl bg-accent-primary/10 flex items-center justify-center group-hover:bg-accent-primary/20 transition-colors">
                        <FaMapMarkerAlt className="w-5 h-5 text-accent-primary" />
                      </div>
                      <div className="ml-4">
                        <p className="text-gray-400 text-sm">Location</p>
                        <p className="text-white">Dubai, United Arab Emirates</p>
                      </div>
                    </div>

                    <div className="flex items-center group">
                      <div className="w-12 h-12 rounded-xl bg-accent-primary/10 flex items-center justify-center group-hover:bg-accent-primary/20 transition-colors">
                        <FaPhone className="w-5 h-5 text-accent-primary" />
                      </div>
                      <div className="ml-4">
                        <p className="text-gray-400 text-sm">Phone</p>
                        <p className="text-white">Available upon request</p>
                      </div>
                    </div>
                  </div>

                  {/* Social Links */}
                  <div className="mt-8 pt-8 border-t border-accent-primary/10">
                    <h3 className="text-white font-semibold mb-4">Connect with me</h3>
                    <div className="flex space-x-4">
                      <a
                        href="https://github.com/AddjoyBarreto"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="w-10 h-10 rounded-lg bg-dark-200 flex items-center justify-center hover:bg-accent-primary/20 transition-colors group"
                      >
                        <FaGithub className="w-5 h-5 text-gray-400 group-hover:text-accent-primary transition-colors" />
                      </a>
                      <a
                        href="https://www.linkedin.com/in/addjoybarreto"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="w-10 h-10 rounded-lg bg-dark-200 flex items-center justify-center hover:bg-accent-primary/20 transition-colors group"
                      >
                        <FaLinkedin className="w-5 h-5 text-gray-400 group-hover:text-accent-primary transition-colors" />
                      </a>
                    </div>
                  </div>
                </div>
              </motion.div>

              {/* Contact Form */}
              <motion.div
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                className="lg:col-span-3 bg-dark-100 rounded-2xl p-8 border border-accent-primary/10"
              >
                <h2 className="text-2xl font-bold text-white mb-6">Send Message</h2>
                <form onSubmit={handleSubmit} className="space-y-6">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div className="relative">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                        className="block w-full px-4 py-3 bg-dark-200 border border-accent-primary/20 rounded-xl focus:border-accent-primary focus:outline-none text-white transition-colors peer pt-6"
                        placeholder=" "
                      />
                      <label
                        htmlFor="name"
                        className="absolute text-sm text-gray-400 duration-300 transform scale-75 top-1 left-4 z-10 origin-[0] 
                        peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-2 
                        peer-focus:scale-75 peer-focus:-translate-y-0 peer-focus:text-accent-primary"
                      >
                        Your Name
                      </label>
                    </div>
                    <div className="relative">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                        className="block w-full px-4 py-3 bg-dark-200 border border-accent-primary/20 rounded-xl focus:border-accent-primary focus:outline-none text-white transition-colors peer pt-6"
                        placeholder=" "
                      />
                      <label
                        htmlFor="email"
                        className="absolute text-sm text-gray-400 duration-300 transform scale-75 top-1 left-4 z-10 origin-[0] 
                        peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-2 
                        peer-focus:scale-75 peer-focus:-translate-y-0 peer-focus:text-accent-primary"
                      >
                        Email Address
                      </label>
                    </div>
                  </div>

                  <div className="relative">
                    <input
                      type="text"
                      id="subject"
                      name="subject"
                      value={formData.subject}
                      onChange={handleChange}
                      required
                      className="block w-full px-4 py-3 bg-dark-200 border border-accent-primary/20 rounded-xl focus:border-accent-primary focus:outline-none text-white transition-colors peer pt-6"
                      placeholder=" "
                    />
                    <label
                      htmlFor="subject"
                      className="absolute text-sm text-gray-400 duration-300 transform scale-75 top-1 left-4 z-10 origin-[0] 
                      peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-2 
                      peer-focus:scale-75 peer-focus:-translate-y-0 peer-focus:text-accent-primary"
                    >
                      Subject
                    </label>
                  </div>

                  <div className="relative">
                    <textarea
                      id="message"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      required
                      rows="6"
                      className="block w-full px-4 py-3 bg-dark-200 border border-accent-primary/20 rounded-xl focus:border-accent-primary focus:outline-none text-white resize-none transition-colors peer pt-6"
                      placeholder=" "
                    ></textarea>
                    <label
                      htmlFor="message"
                      className="absolute text-sm text-gray-400 duration-300 transform scale-75 top-1 left-4 z-10 origin-[0] 
                      peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-2 
                      peer-focus:scale-75 peer-focus:-translate-y-0 peer-focus:text-accent-primary"
                    >
                      Your Message
                    </label>
                  </div>

                  {status.message && (
                    <div className={`p-4 rounded-xl ${status.type === 'success' ? 'bg-green-500/10 text-green-500' : 'bg-red-500/10 text-red-500'}`}>
                      {status.message}
                    </div>
                  )}

                  <motion.button
                    type="submit"
                    disabled={isSubmitting}
                    className="w-full px-8 py-4 bg-gradient-primary text-white rounded-xl font-semibold shadow-neon hover:shadow-neon-strong transition-all duration-300 disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center gap-2"
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                  >
                    {isSubmitting ? (
                      'Sending...'
                    ) : (
                      <>
                        Send Message
                        <FaPaperPlane className="w-4 h-4" />
                      </>
                    )}
                  </motion.button>
                </form>
              </motion.div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact; 