import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = ({ title, description, path = '' }) => {
  const baseUrl = 'https://addjoybarreto.com';
  const fullUrl = `${baseUrl}${path}`;
  const defaultDescription = 'Senior Software Engineer specializing in full-stack development, cloud architecture, and technical leadership.';

  return (
    <Helmet>
      <title>{title ? `${title} | Addjoy Barreto` : 'Addjoy Barreto - Senior Software Engineer'}</title>
      <meta name="description" content={description || defaultDescription} />
      <link rel="canonical" href={fullUrl} />

      <meta property="og:url" content={fullUrl} />
      <meta property="og:title" content={title || 'Addjoy Barreto - Senior Software Engineer'} />
      <meta property="og:description" content={description || defaultDescription} />

      <meta name="twitter:title" content={title || 'Addjoy Barreto - Senior Software Engineer'} />
      <meta name="twitter:description" content={description || defaultDescription} />
    </Helmet>
  );
};

export default SEO; 