import React from 'react';
import { motion } from 'framer-motion';
import { FaBriefcase, FaArrowRight, FaBuilding, FaMapMarkerAlt, FaClock } from 'react-icons/fa';
import SEO from './SEO';

const Experience = () => {
  const workExperience = [
    {
      title: "Full Stack Software Engineer",
      company: "Voizzit",
      location: "Dubai, UAE",
      duration: "2024 - Present",
      description: [
        "Engineered a global-scale streaming platform with unique features, impacting millions worldwide",
        "Delivered full-stack solutions using Next.js, Expo, NestJS, Redis, PostgreSQL, and AWS",
        "Designed and launched major features to enhance user engagement and platform differentiation",
        "Implemented real-time data processing and streaming protocols for seamless user experience"
      ],
      technologies: ["NestJS", "NextJS", "Socket.IO", "Redis", "PostgreSQL", "AWS", "DigitalOcean", "Expo", "Tamagui"]
    },
    {
      title: "Full Stack Software Engineer",
      company: "Innobayt Innovation Solutions",
      location: "Dubai, UAE",
      duration: "2022 - 2024",
      description: [
        "Automated industry workflows, integrating hardware and software systems for IoT solutions",
        "Built an e-commerce marketplace with cryptocurrency wallets, Stripe, and Google Pay integration",
        "Developed industrial automation systems with real-time monitoring and control capabilities",
        "Implemented IoT sensor networks and data processing pipelines for manufacturing automation",
        "Created blockchain-based payment solutions and smart contract integrations"
      ],
      technologies: [
        "React", "Next.js", "Node.js", 
        "IoT Protocols", "Industrial Automation",
        "Crypto Wallets", "Blockchain APIs",
        "Sensor Networks", "MQTT",
        "Real-time Processing"
      ]
    },
    {
      title: "Full Stack Software Engineer",
      company: "Intuio Software Labs Pvt. Ltd.",
      location: "Goa, India",
      duration: "2020 - 2021",
      description: [
        "Created a custom quoting system for instant automotive price estimations",
        "Developed a vacation rental platform with smart B2C API integrations",
        "Built a job portal with intelligent profile matching and streamlined recruitment workflows"
      ],
      technologies: ["React", "React Native", "Node.js", "Express", "MongoDB", "MySQL", "Stripe", "Google APIs", "Socket.IO"]
    },
    {
      title: "Intern",
      company: "DIGISOL Systems Ltd.",
      location: "Goa, India",
      duration: "2019 (2 months)",
      description: [
        "Developed a Visitor Management App using C#/.NET for visitor check-ins and appointment tracking"
      ],
      technologies: ["C#", ".NET"]
    },
    {
      title: "Intern",
      company: "Chowgule Brothers Pvt. Ltd.",
      location: "Goa, India",
      duration: "2018 (2 months)",
      description: [
        "Streamlined data management by digitizing paper records into an organized, centralized database"
      ],
      technologies: ["Database Management", "Data Entry Systems"]
    }
  ];

  return (
    <>
      <SEO 
        title="Experience"
        description="Professional experience including roles at Voizzit, Innobayt Innovation Solutions, and more. Expertise in React, Node.js, AWS, and cloud architecture."
        path="/experience"
      />
      <section className="py-20 bg-dark-200 min-h-screen relative overflow-hidden">
        {/* Animated background elements */}
        <div className="absolute inset-0 overflow-hidden pointer-events-none">
          <div className="absolute w-[800px] h-[800px] bg-accent-primary/5 rounded-full blur-3xl -top-96 -left-96 animate-float"></div>
          <div className="absolute w-[600px] h-[600px] bg-neon-purple/5 rounded-full blur-3xl top-1/2 -right-96 animate-float" style={{ animationDelay: '2s' }}></div>
        </div>

        <div className="container mx-auto px-4 relative z-10">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="max-w-5xl mx-auto"
          >
            <h1 className="text-4xl font-bold text-center mb-16 bg-gradient-primary bg-clip-text text-transparent">
              Work Experience
            </h1>

            <div className="relative space-y-4">
              {/* Vertical line */}
              <div className="absolute left-[27px] top-3 bottom-3 w-0.5 bg-gradient-to-b from-accent-primary via-accent-primary/50 to-transparent"></div>

              {workExperience.map((job, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, x: -50 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  className="group relative"
                >
                  {/* Timeline dot */}
                  <div className="absolute left-0 w-12 h-12 rounded-full bg-dark-100 border-2 border-accent-primary/20 group-hover:border-accent-primary/50 flex items-center justify-center transition-all shadow-lg group-hover:shadow-accent-primary/20">
                    <FaBriefcase className="w-5 h-5 text-accent-primary" />
                  </div>

                  <div className="ml-16 bg-dark-100 rounded-lg p-4 border border-accent-primary/10 group-hover:border-accent-primary/30 transition-all shadow-lg hover:shadow-xl hover:shadow-accent-primary/10">
                    <div className="flex flex-wrap items-start justify-between mb-2">
                      <div>
                        <h3 className="text-xl font-bold text-white group-hover:text-accent-primary transition-colors mb-2">
                          {job.title}
                        </h3>
                        <div className="flex flex-wrap items-center gap-2 text-gray-400 text-sm">
                          <div className="flex items-center gap-1.5 bg-dark-200/50 px-2.5 py-1 rounded-full">
                            <FaBuilding className="w-3.5 h-3.5 text-accent-primary" />
                            <span>{job.company}</span>
                          </div>
                          <div className="flex items-center gap-1.5 bg-dark-200/50 px-2.5 py-1 rounded-full">
                            <FaMapMarkerAlt className="w-3.5 h-3.5 text-accent-primary" />
                            <span>{job.location}</span>
                          </div>
                          <div className="flex items-center gap-1.5 bg-dark-200/50 px-2.5 py-1 rounded-full">
                            <FaClock className="w-3.5 h-3.5 text-accent-primary" />
                            <span>{job.duration}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="space-y-2 mb-2">
                      {job.description.map((item, i) => (
                        <motion.div
                          key={i}
                          className="flex items-start group/item bg-dark-200/30 rounded p-1 hover:bg-dark-200/50 transition-all"
                          whileHover={{ x: 10 }}
                        >
                          <FaArrowRight className="w-3.5 h-3.5 text-accent-primary mr-2 mt-0.5 opacity-0 group-hover/item:opacity-100 transition-opacity" />
                          <p className="text-gray-300 text-sm leading-relaxed">{item}</p>
                        </motion.div>
                      ))}
                    </div>

                    <div className="flex flex-wrap gap-2 pt-2 border-t border-accent-primary/10">
                      {job.technologies.map((tech, i) => (
                        <motion.span
                          key={i}
                          className="px-2.5 py-1 bg-dark-200/50 text-accent-primary rounded-full text-sm border border-accent-primary/20 hover:border-accent-primary hover:shadow-neon transition-all"
                          whileHover={{ scale: 1.05 }}
                          whileTap={{ scale: 0.95 }}
                        >
                          {tech}
                        </motion.span>
                      ))}
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>
      </section>
    </>
  );
};

export default Experience; 