import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { FaBars, FaTimes } from 'react-icons/fa';

const Navigation = () => {
  const [scrolled, setScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const navItems = [
    { path: '/', label: 'Home' },
    { path: '/about', label: 'About' },
    { path: '/experience', label: 'Experience' },
    { path: '/projects', label: 'Projects' },
    { path: '/skills', label: 'Skills' },
    // { path: '/blog', label: 'Blog' },
    { path: '/contact', label: 'Contact' }
  ];

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMenu = () => setIsOpen(!isOpen);

  const menuItemVariants = {
    closed: { opacity: 0, x: -20 },
    open: { opacity: 1, x: 0 }
  };

  return (
    <AnimatePresence>
      <motion.nav
        key="navigation"
        className={`fixed top-0 w-full h-20 flex justify-between items-center px-12 z-50 transition-all duration-300 ${
          scrolled ? 'bg-dark-100/95 backdrop-blur shadow-lg' : 'bg-transparent'
        }`}
        initial={{ y: -100 }}
        animate={{ y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Link to="/" className="no-underline">
          <motion.div
            className="text-2xl font-bold text-accent-primary cursor-pointer"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            Addjoy Barreto
          </motion.div>
        </Link>

        {/* Desktop Navigation */}
        <div className="hidden lg:flex gap-8">
          {navItems.map((item) => (
            <Link
              key={item.path}
              to={item.path}
              className={`px-4 py-2 rounded-lg transition-all no-underline ${
                location.pathname === item.path
                  ? 'text-accent-primary bg-accent-primary/10'
                  : 'text-gray-400 hover:text-accent-primary hover:bg-accent-primary/10'
              }`}
            >
              <motion.span whileHover={{ scale: 1.05 }}>
                {item.label}
              </motion.span>
            </Link>
          ))}
        </div>

        {/* Mobile Menu Button */}
        <motion.button
          onClick={toggleMenu}
          className="lg:hidden inline-flex items-center justify-center p-2 rounded-lg text-accent-primary hover:bg-accent-primary/10 focus:outline-none"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          aria-expanded={isOpen}
          aria-label="Toggle menu"
        >
          {isOpen ? (
            <FaTimes className="h-6 w-6" />
          ) : (
            <FaBars className="h-6 w-6" />
          )}
        </motion.button>
      </motion.nav>

      {/* Mobile Navigation */}
      <motion.div
        initial={false}
        animate={{
          height: isOpen ? 'auto' : 0,
          opacity: isOpen ? 1 : 0
        }}
        transition={{ duration: 0.3 }}
        className="fixed top-20 left-0 right-0 z-40 lg:hidden overflow-hidden bg-dark-100/95 backdrop-blur border-t border-accent-primary/10"
      >
        <motion.div 
          className="px-4 py-2"
          initial="closed"
          animate="open"
          variants={{
            open: {
              transition: {
                staggerChildren: 0.1
              }
            }
          }}
        >
          {navItems.map((item) => (
            <motion.div
              key={item.path}
              variants={menuItemVariants}
              transition={{ duration: 0.3 }}
            >
              <Link
                to={item.path}
                onClick={() => setIsOpen(false)}
                className={`block px-4 py-3 rounded-lg transition-all no-underline ${
                  location.pathname === item.path
                    ? 'text-accent-primary bg-accent-primary/10'
                    : 'text-gray-400 hover:text-accent-primary hover:bg-accent-primary/10'
                }`}
              >
                <motion.span
                  whileHover={{ scale: 1.05 }}
                  className="block"
                >
                  {item.label}
                </motion.span>
              </Link>
            </motion.div>
          ))}
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default Navigation; 