import React from 'react';
import { motion } from 'framer-motion';
import { FaTrophy, FaGraduationCap } from 'react-icons/fa';
import SEO from './SEO';

const About = () => {
  const achievements = [
    {
      title: "Exceptional Performance Award",
      organization: "Innobayt Innovation Solutions",
      date: "Dec 2022",
      description: "Outstanding technical contributions and innovation"
    },
    {
      title: "Process Automation Pioneer",
      description: "Led large-scale automation initiatives enhancing industry processes"
    },
    {
      title: "E-commerce Innovation",
      description: "Developed high-impact platform with integrated crypto payments"
    }
  ];

  return (
    <>
      <SEO 
        title="About"
        description="Senior Software Engineer with over 5 years of experience in full-stack development, cloud architecture, and technical leadership. Award-winning developer based in Dubai."
        path="/about"
      />
      <section className="py-20 bg-dark-200 min-h-screen relative overflow-hidden">
        {/* Background decoration */}
        <div className="absolute inset-0 overflow-hidden pointer-events-none">
          <div className="absolute w-[800px] h-[800px] bg-accent-primary/5 rounded-full blur-3xl -top-96 -left-96 animate-float"></div>
          <div className="absolute w-[600px] h-[600px] bg-neon-purple/5 rounded-full blur-3xl top-1/2 -right-96 animate-float" style={{ animationDelay: '2s' }}></div>
        </div>

        <div className="container mx-auto px-4 relative z-10">
          {/* Profile Section */}
          <motion.div
            className="max-w-4xl mx-auto bg-dark-100 rounded-xl p-8 shadow-lg border border-accent-primary/10 hover:border-accent-primary/30 transition-all mb-16"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
          >
            <div className="flex flex-col items-center text-center mb-8">
              <motion.h1 
                className="text-4xl font-bold mb-6 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-blue-300"
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
              >
                About Me
              </motion.h1>
              <div className="w-20 h-1 bg-gradient-primary rounded-full mb-8"></div>
            </div>
            <p className="text-gray-300 text-lg leading-relaxed">
              With over half a decade of hands-on experience across diverse technologies, I
              excel in both independent projects and collaborative team environments. I take
              pride in the depth of my expertise, not just in repeating the same tasks year
              after year, but by working on diverse projects across multiple domains to
              continuously expand my knowledge and skills.
            </p>
          </motion.div>

          {/* Achievements Section */}
          <motion.div
            className="mb-16"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
          >
            <h2 className="text-3xl font-bold text-center mb-4 text-transparent bg-clip-text bg-gradient-primary">
              Achievements & Awards
            </h2>
            <div className="w-20 h-1 bg-gradient-primary rounded-full mx-auto mb-12"></div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 max-w-5xl mx-auto">
              {achievements.map((achievement, index) => (
                <motion.div
                  key={index}
                  className="bg-dark-100 rounded-xl p-6 border border-accent-primary/10 hover:border-accent-primary/30 transition-all group"
                  whileHover={{ y: -5 }}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.1 * index }}
                >
                  <div className="flex items-center justify-center mb-4">
                    <div className="w-12 h-12 rounded-full bg-accent-primary/10 flex items-center justify-center group-hover:scale-110 transition-transform">
                      <FaTrophy className="text-accent-primary w-6 h-6" />
                    </div>
                  </div>
                  <h3 className="text-xl font-bold text-white text-center mb-3">{achievement.title}</h3>
                  {achievement.organization && (
                    <p className="text-accent-primary text-center mb-2">{achievement.organization}</p>
                  )}
                  {achievement.date && (
                    <p className="text-gray-400 text-sm text-center mb-2">{achievement.date}</p>
                  )}
                  <p className="text-gray-300 text-center">{achievement.description}</p>
                </motion.div>
              ))}
            </div>
          </motion.div>

          {/* Education Section */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.6 }}
            className="max-w-3xl mx-auto"
          >
            <h2 className="text-3xl font-bold text-center mb-4 text-transparent bg-clip-text bg-gradient-primary">
              Education
            </h2>
            <div className="w-20 h-1 bg-gradient-primary rounded-full mx-auto mb-12"></div>
            <div className="bg-dark-100 rounded-xl p-8 border border-accent-primary/10 hover:border-accent-primary/30 transition-all">
              <div className="flex items-center">
                <div className="w-16 h-16 rounded-full bg-accent-primary/10 flex items-center justify-center mr-6 group-hover:scale-110 transition-transform">
                  <FaGraduationCap className="text-accent-primary w-8 h-8" />
                </div>
                <div>
                  <h3 className="text-2xl font-bold text-white mb-2">Bachelor of Engineering in Computer Science</h3>
                  <p className="text-accent-primary text-lg mb-1">Padre Conceicao College of Engineering, Goa</p>
                  <p className="text-gray-400">2016 - 2020</p>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </section>
    </>
  );
};

export default About; 