import React from 'react';
import { motion } from 'framer-motion';
import { 
  FaCode, FaServer, FaDatabase, FaTools, 
  FaCloud, FaBrain, FaShieldAlt, FaProjectDiagram,
  FaRobot, FaNetworkWired, FaBitcoin 
} from 'react-icons/fa';
import SEO from './SEO';

const Skills = () => {
  const skillCategories = {
    "Architecture & Design": {
      icon: <FaProjectDiagram />,
      skills: [
        "System Design",
        "Microservices",
        "Domain-Driven Design",
        "Event-Driven Architecture",
        "Design Patterns",
        "API Design",
        "Scalable Systems",
        "Distributed Systems",
        "Service Mesh",
        "Message Queues"
      ]
    },
    "Frontend & Mobile": {
      icon: <FaCode />,
      skills: [
        "React/Next.js",
        "TypeScript",
        "React Native",
        "Redux/Zustand",
        "Tailwind CSS",
        "Performance Optimization",
        "Responsive Design",
        "Progressive Web Apps",
        "Web Accessibility",
        "Cross-platform Development",
        "UI/UX Best Practices",
        "State Management"
      ]
    },
    "Backend & Infrastructure": {
      icon: <FaServer />,
      skills: [
        "Node.js/NestJS",
        "Python/Django",
        "PostgreSQL/MongoDB",
        "Redis/Caching",
        "Docker/Kubernetes",
        "AWS/Cloud Architecture",
        "RESTful APIs",
        "GraphQL",
        "Websockets",
        "Microservices",
        "Load Balancing",
        "Service Discovery"
      ]
    },
    "Database & Storage": {
      icon: <FaDatabase />,
      skills: [
        "PostgreSQL",
        "MongoDB",
        "Redis",
        "Database Design",
        "Query Optimization",
        "Data Modeling",
        "Indexing Strategies",
        "Replication",
        "Sharding",
        "Backup Solutions"
      ]
    },
    "Cloud & DevOps": {
      icon: <FaCloud />,
      skills: [
        "AWS Services",
        "Docker",
        "Kubernetes",
        "CI/CD Pipelines",
        "Infrastructure as Code",
        "Terraform",
        "Cloud Formation",
        "Monitoring & Logging",
        "Auto Scaling",
        "Serverless Architecture",
        "Container Orchestration",
        "Cloud Cost Optimization"
      ]
    },
    "Security & Performance": {
      icon: <FaShieldAlt />,
      skills: [
        "OAuth/JWT",
        "API Security",
        "SSL/TLS",
        "Security Best Practices",
        "Performance Tuning",
        "Load Testing",
        "Penetration Testing",
        "OWASP Guidelines",
        "Data Encryption",
        "Security Auditing"
      ]
    },
    "Development Practices": {
      icon: <FaTools />,
      skills: [
        "Git Flow",
        "Agile/Scrum",
        "Code Review",
        "Test-Driven Development",
        "Continuous Integration",
        "Documentation",
        "Clean Code Principles",
        "Pair Programming",
        "Technical Writing",
        "Problem Solving"
      ]
    },
    "Leadership & Soft Skills": {
      icon: <FaBrain />,
      skills: [
        "Technical Leadership",
        "Team Mentoring",
        "Project Planning",
        "Stakeholder Management",
        "Code Reviews",
        "Architecture Planning",
        "Knowledge Sharing",
        "Process Improvement",
        "Team Collaboration",
        "Communication"
      ]
    },
    "IoT & Automation": {
      icon: <FaRobot />,
      skills: [
        "IoT Systems Integration",
        "Sensor Networks",
        "Real-time Data Processing",
        "Industrial Automation",
        "Hardware Integration",
        "Protocol Implementation",
        "MQTT/CoAP",
        "Embedded Systems",
        "PLC Integration",
        "Process Automation"
      ]
    },
    "Blockchain & Fintech": {
      icon: <FaBitcoin />,
      skills: [
        "Crypto Wallets Integration",
        "Smart Contracts",
        "Payment Gateways",
        "Stripe Integration",
        "Web3.js",
        "Blockchain APIs",
        "DeFi Protocols",
        "Digital Payments",
        "Transaction Processing",
        "Financial Systems"
      ]
    },
    "Network & Protocols": {
      icon: <FaNetworkWired />,
      skills: [
        "TCP/IP",
        "WebSockets",
        "API Gateway",
        "Network Security",
        "Protocol Design",
        "Load Balancing",
        "Service Mesh",
        "Network Monitoring",
        "Data Streaming",
        "Real-time Communication"
      ]
    }
  };

  return (
    <>
      <SEO 
        title="Skills"
        description="Technical expertise in Architecture & Design, Frontend & Mobile Development, Backend & Infrastructure, Cloud & DevOps, and more."
        path="/skills"
      />
      <section className="py-20 bg-dark-200 min-h-screen">
        <div className="container mx-auto px-4">
          <motion.h1 
            className="text-4xl font-bold text-center mb-16 bg-gradient-primary bg-clip-text text-transparent"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
          >
            Skills & Technologies
          </motion.h1>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 max-w-7xl mx-auto">
            {Object.entries(skillCategories).map(([category, { icon, skills }], index) => (
              <motion.div
                key={category}
                className="bg-dark-100 rounded-xl p-6 border border-accent-primary/10 hover:border-accent-primary/30 transition-all"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <div className="flex items-center mb-6">
                  <div className="w-10 h-10 flex items-center justify-center text-accent-primary">
                    {React.cloneElement(icon, { className: 'w-6 h-6' })}
                  </div>
                  <h2 className="text-xl font-bold text-white ml-4">{category}</h2>
                </div>
                <div className="flex flex-wrap gap-2">
                  {skills.map((skill, i) => (
                    <span
                      key={i}
                      className="px-3 py-1 bg-dark-200 text-accent-primary rounded-full text-sm border border-accent-primary/20 hover:border-accent-primary hover:shadow-neon transition-all"
                    >
                      {skill}
                    </span>
                  ))}
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Skills;