import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <section className="min-h-screen flex items-center justify-center bg-dark-300">
      <div className="text-center">
        <motion.h1
          className="text-6xl font-bold text-accent-primary mb-6"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
        >
          404
        </motion.h1>
        <motion.p
          className="text-2xl text-gray-400 mb-8"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.1 }}
        >
          Page not found
        </motion.p>
        <Link to="/">
          <motion.button
            className="px-8 py-4 bg-gradient-primary text-white rounded-lg font-semibold shadow-neon hover:shadow-neon-strong transition-all duration-300 hover:-translate-y-1"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Go Home
          </motion.button>
        </Link>
      </div>
    </section>
  );
};

export default NotFound; 