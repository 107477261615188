import React from 'react';
import { motion } from 'framer-motion';

const Blog = () => {
  const posts = [
    {
      title: "Building Scalable Microservices Architecture",
      excerpt: "Learn how to design and implement a scalable microservices architecture using modern tools and best practices.",
      date: "Mar 15, 2024",
      readTime: "10 min read",
      category: "Architecture"
    },
    {
      title: "Advanced CI/CD Patterns",
      excerpt: "Explore advanced continuous integration and deployment patterns for enterprise applications.",
      date: "Mar 10, 2024",
      readTime: "8 min read",
      category: "DevOps"
    },
    {
      title: "Cloud Cost Optimization Strategies",
      excerpt: "Practical strategies to optimize your cloud infrastructure costs without compromising performance.",
      date: "Mar 5, 2024",
      readTime: "12 min read",
      category: "Cloud"
    }
  ];

  return (
    <section className="py-20 bg-white" id="blog">
      <div className="container mx-auto px-4">
        <motion.h2 
          className="text-4xl font-bold text-center mb-16 bg-gradient-primary bg-clip-text text-transparent"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
        >
          Technical Blog
        </motion.h2>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {posts.map((post, index) => (
            <motion.article
              key={index}
              className="bg-gray-50 rounded-xl overflow-hidden shadow-md hover:shadow-xl transition-all"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <div className="p-8">
                <span className="px-4 py-2 bg-blue-100 text-blue-600 rounded-full text-sm font-medium">
                  {post.category}
                </span>
                <h3 className="text-xl font-bold mt-4 mb-3 text-gray-800">
                  {post.title}
                </h3>
                <p className="text-gray-600 mb-4">
                  {post.excerpt}
                </p>
                <div className="flex items-center justify-between text-sm text-gray-500">
                  <span>{post.date}</span>
                  <span>{post.readTime}</span>
                </div>
              </div>
            </motion.article>
          ))}
        </div>

        <div className="text-center mt-12">
          <button className="px-8 py-4 bg-gradient-primary text-white rounded-lg font-semibold shadow-lg hover:shadow-xl transition-all hover:-translate-y-1">
            View All Posts
          </button>
        </div>
      </div>
    </section>
  );
};

export default Blog; 